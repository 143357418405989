<template>
    <div id="cart-items">
        <span v-if="cart_items">
            <div v-if="cart_items.length>0" class="cart-items-items">
                <div v-for="(cart_item, index) in cart_items" :key="index">
                    <div v-if="cart_item.glass.type=='accessoire'" class='cart-item'>
                        <img class='product-img' :src='photos[index]'  @click="displayPhotoBig(photos[index])" />
                        <div class='product-details'>
                            <!-- <span class='quantity'>
                                <span @click='minusCartItem($event,cart_item.id,cart_item.quantity)' class='minus'>-</span>
                                <span class='qtty'>{{cart_item.quantity}}</span>
                                <span @click='plusCartItem($event,cart_item.id)' class='plus'>+</span>
                            </span> -->
                            <div class='name'>{{cart_item.glass.name}}

                                <p>{{cart_item.glass.description}}</p>
                            </div>
                            <div class='item-amount'>{{cart_item.amount}}€</div>
                        </div>
                    </div>
                    <!-- <div v-else-if="cart_item.glass.type=='collaboration'" class='cart-item'>
                        <img class='product-img' :src='cart_item.product_variation_item.photos[0]'  @click="displayPhotoBig(cart_item.product_variation_item.photos[0])" />
                        <div class='product-details'>
                            <span class='quantity'>
                                <span @click='minusCartItem($event,cart_item.id,cart_item.quantity)' class='minus'>-</span>
                                <span class='qtty'>{{cart_item.quantity}}</span>
                                <span @click='plusCartItem($event,cart_item.id)' class='plus'>+</span>
                            </span> 
                            <div class='name'>{{cart_item.glass.name}}
                                <p v-if="cart_item.product_variation_item!=null">
                                    {{cart_item.product_variation_item.name}}</p>
                                <p v-else>{{cart_item.glass.description}}</p>
                            </div>
                            <div class='item-amount'>{{cart_item.amount}}€</div>
                        </div>
                    </div> -->
                    <div v-else class='cart-item'>
                        <div class="product-img-container">
                        <CartImage :cart_item="cart_item" /></div>
                        <div class="name-product">
                            {{cart_item.glass.name}}<br />
                            {{cart_item.glass.brand_name}}
                            <div class="contenumob">
                            <div class='product-details '>
                                <!-- <span class='quantity'>
                                    <span @click='minusCartItem($event,cart_item.id,cart_item.quantity)' class='minus'>-</span>
                                    <span class='qtty'>{{cart_item.quantity}}</span>
                                    <span @click='plusCartItem($event,cart_item.id)' class='plus'>+</span>
                                </span> -->
                                <div v-if="(cart_item.size!=null && cart_item.size!='onmeasure' && cart_item.scan==null)  || (cart_item.scan==null && cart_item.size!='onmeasure')" class="product-detail">
                                    <label>One-size</label>
                                </div>
                                <div v-else class="product-detail">
                                    <label v-if="language=='fr'">Sur-mesure</label>
                                    <label v-else>Custom-fit</label>
                                </div>
                                <div class="product-detail">
                                    <label v-if="language=='fr'">Quantité : {{cart_item.quantity}}</label>
                                    <label v-else>Quantity : {{cart_item.quantity}}</label>
                                </div>
                            
                                <div class='product-detail' @click="toggleCart('glass', index)" :class="{open:toggleGlass[index]}">
                                    <label>
                                        <span v-if="cart_item.glass_option!==null && cart_item.glass_type!==null"><span v-if="language=='fr'">{{cart_item.glass_option.name}} </span>
                                            <span v-else>{{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}} </span> - {{cart_item.glass_type.name.replace('Sans correction','No correction')}}</span>
                                        <span v-else-if="cart_item.glass_option!==null">
                                            <span v-if="language=='fr'">{{cart_item.glass_option.name}} </span>
                                            <span v-else>{{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}} </span>
                                            
                                            - <span v-if="language=='fr'">Sans correction</span><span v-else>No correction</span></span>
                                        <span v-else-if="cart_item.glass_type!==null">{{cart_item.glass_type.name.replace('Sans correction','No correction')}}</span>
                                        <span v-else><span v-if="language=='fr'">Sans correction</span><span v-else>No correction</span></span>
                                    </label>
                                    
                                    <div class="product_detail_content">
                                        <template v-if="language=='fr'">
                                            <!-- Verres :
                                            <span v-if="cart_item.glass_option!==null"> {{cart_item.glass_option.name}}</span>
                                            <span v-else>Aucune option</span>
                                            
                                            <span v-if="cart_item.glass_type!==null"><br />Type de verre :
                                                {{cart_item.glass_type.name}} </span> -->


                                            <span v-if="cart_item.tint!==null">Teinte de verre : {{cart_item.tint.name}}</span>

                                            <span class='subtotal' v-if="(cart_item.tint!=null || cart_item.glass_option!=null  || cart_item.glass_type!=null)">

                                                <span
                                                    v-if="cart_item.tint!==null && cart_item.glass_option!==null && cart_item.glass_type!==null">{{cart_item.tint.price+cart_item.glass_option.price+cart_item.glass_type.price}}</span>

                                                <span
                                                    v-else-if="cart_item.tint!==null && cart_item.glass_option!==null">{{cart_item.tint.price+cart_item.glass_option.price}}</span>

                                                <span
                                                    v-else-if="cart_item.glass_option!==null">{{cart_item.glass_option.price}}</span>

                                                <span v-else-if="cart_item.tint!==null">{{cart_item.tint.price}}</span>

                                                <span v-if="cart_item.tint!==null || cart_item.glass_option!==null || cart_item.glass_type!==null">€</span>
                                            </span>
                                        </template>
                                        <template v-else>
                                            Lenses :
                                            <span v-if="cart_item.glass_option!==null"> {{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}}</span>
                                            <span v-else>No option</span>
                                            
                                            <span v-if="cart_item.glass_type!==null"><br />Lens type:
                                                {{cart_item.glass_type.name.replace('Sans correction','No correction')}} </span>


                                            <span v-if="cart_item.tint!==null"><br />Lens tint: {{cart_item.tint.name}}</span>

                                            <span class='subtotal' v-if="(cart_item.tint!=null || cart_item.glass_option!=null  || cart_item.glass_type!=null)">

                                                <span
                                                    v-if="cart_item.tint!==null && cart_item.glass_option!==null && cart_item.glass_type!==null">{{cart_item.tint.price+cart_item.glass_option.price+cart_item.glass_type.price}}</span>

                                                <span
                                                    v-else-if="cart_item.tint!==null && cart_item.glass_option!==null">{{cart_item.tint.price+cart_item.glass_option.price}}</span>

                                                <span
                                                    v-else-if="cart_item.glass_option!==null">{{cart_item.glass_option.price}}</span>

                                                <span v-else-if="cart_item.tint!==null">{{cart_item.tint.price}}</span>

                                                <span v-if="cart_item.tint!==null || cart_item.glass_option!==null || cart_item.glass_type!==null">€</span>
                                            </span>
                                        </template>
                                    </div>
                                </div>
                                <div v-if="language=='fr'" class='product-detail' @click="toggleCart('frame', index)" :class="{open:toggleFrame[index]}">
                                    <div class="product_detail_content">
                                        <div v-for="order_item_customization in cart_item.order_item_customizations" :key="order_item_customization.id">
                                            <span v-if="(order_item_customization.model_part.type=='color' || order_item_customization.model_part.type=='texture_color') && order_item_customization.model_part.render_type!='manchon' && order_item_customization.model_part.render_type!='hinge'">
                                            <span class="detail-custom" v-if="order_item_customization.model_part.type=='color'">
                                                    <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                    {{order_item_customization.color.name}}
                                                    <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                                </span>
                                                <span class="detail-custom" v-else>
                                                    <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                    {{order_item_customization.color.name}}
                                                    <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                                
                                                </span>
                                                <span class="detail-custom" v-if="order_item_customization.texture">
                                                
                                                    Texture {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                    {{order_item_customization.texture.name}}
                                                    <span v-if="order_item_customization.texture.price" class='subtotal'>{{order_item_customization.texture.price}}€</span>
                                                </span>
                                                <span class="detail-custom" v-if="order_item_customization.texture_style">
                                                <span v-if="language=='fr'">Style de texture</span><span v-else>Texture style</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                    {{order_item_customization.texture_style.name}}
                                                    <span v-if="order_item_customization.texture_style.price" class='subtotal'>{{order_item_customization.texture_style.price}}€</span>
                                                </span>
                                            </span>
                                        </div>


                                        <div v-if="cart_item.engraving_text!=null && cart_item.engraving_text!=''">
                                            Gravure :
                                            {{cart_item.engraving_text}}
                                            <span class='subtotal'>10€</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class='product-detail' @click="toggleCart('frame', index)" :class="{open:toggleFrame[index]}">
                                    <div class="product_detail_content">
                                        <div v-for="order_item_customization in cart_item.order_item_customizations" :key="order_item_customization.id">
                                            <span v-if="(order_item_customization.model_part.type=='color' || order_item_customization.model_part.type=='texture_color') && order_item_customization.model_part.render_type!='manchon' && order_item_customization.model_part.render_type!='hinge'">
                                            <span class="detail-custom" v-if="order_item_customization.model_part.type=='color'">
                                                    <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                    {{order_item_customization.color.name}}
                                                    <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                                </span>
                                                <span class="detail-custom" v-else>
                                                    <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                    {{order_item_customization.color.name}}
                                                    <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                                
                                                </span>
                                                <span class="detail-custom" v-if="order_item_customization.texture">
                                                
                                                    Texture {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                    {{order_item_customization.texture.name}}
                                                    <span v-if="order_item_customization.texture.price" class='subtotal'>{{order_item_customization.texture.price}}€</span>
                                                </span>
                                                <span class="detail-custom" v-if="order_item_customization.texture_style">
                                                <span v-if="language=='fr'">Style de texture</span><span v-else>Texture style</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                    {{order_item_customization.texture_style.name}}
                                                    <span v-if="order_item_customization.texture_style.price" class='subtotal'>{{order_item_customization.texture_style.price}}€</span>
                                                </span>
                                            </span>
                                        </div>


                                        <div v-if="cart_item.engraving_text!=null && cart_item.engraving_text!=''">
                                            Engraving :
                                            {{cart_item.engraving_text}}
                                            <span class='subtotal'>10€</span>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                        </div>
                        <div class='product-details contenudesk'>
                            <!-- <span class='quantity'>
                                <span @click='minusCartItem($event,cart_item.id,cart_item.quantity)' class='minus'>-</span>
                                <span class='qtty'>{{cart_item.quantity}}</span>
                                <span @click='plusCartItem($event,cart_item.id)' class='plus'>+</span>
                            </span> -->
                            <div v-if="(cart_item.size!=null && cart_item.size!='onmeasure' && cart_item.scan==null)  || (cart_item.scan==null && cart_item.size!='onmeasure')" class="product-detail">
                                <label>One-size</label>
                            </div>
                            <div v-else class="product-detail">
                                <label v-if="language=='fr'">Sur-mesure</label>
                                <label v-else>Custom-fit</label>
                            </div>
                            <div class="product-detail">
                                <label v-if="language=='fr'">Quantité : {{cart_item.quantity}}</label>
                                <label v-else>Quantity : {{cart_item.quantity}}</label>
                            </div>
                           
                            <div class='product-detail' @click="toggleCart('glass', index)" :class="{open:toggleGlass[index]}">
                                <label>
                                    <span v-if="cart_item.glass_option!==null && cart_item.glass_type!==null"><span v-if="language=='fr'">{{cart_item.glass_option.name}} </span>
                                        <span v-else>{{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}} </span> - {{cart_item.glass_type.name.replace('Sans correction','No correction')}}</span>
                                    <span v-else-if="cart_item.glass_option!==null">
                                        <span v-if="language=='fr'">{{cart_item.glass_option.name}} </span>
                                        <span v-else>{{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}} </span>
                                        
                                        - <span v-if="language=='fr'">Sans correction</span><span v-else>No correction</span></span>
                                    <span v-else-if="cart_item.glass_type!==null">{{cart_item.glass_type.name.replace('Sans correction','No correction')}}</span>
                                    <span v-else><span v-if="language=='fr'">Sans correction</span><span v-else>No correction</span></span>
                                </label>
                                
                                <div class="product_detail_content">
                                    <template v-if="language=='fr'">
                                        <!-- Verres :
                                        <span v-if="cart_item.glass_option!==null"> {{cart_item.glass_option.name}}</span>
                                        <span v-else>Aucune option</span>
                                        
                                        <span v-if="cart_item.glass_type!==null"><br />Type de verre :
                                            {{cart_item.glass_type.name}} </span> -->


                                        <span v-if="cart_item.tint!==null">Teinte de verre : {{cart_item.tint.name}}</span>

                                        <span class='subtotal' v-if="(cart_item.tint!=null || cart_item.glass_option!=null  || cart_item.glass_type!=null)">

                                            <span
                                                v-if="cart_item.tint!==null && cart_item.glass_option!==null && cart_item.glass_type!==null">{{cart_item.tint.price+cart_item.glass_option.price+cart_item.glass_type.price}}</span>

                                            <span
                                                v-else-if="cart_item.tint!==null && cart_item.glass_option!==null">{{cart_item.tint.price+cart_item.glass_option.price}}</span>

                                            <span
                                                v-else-if="cart_item.glass_option!==null">{{cart_item.glass_option.price}}</span>

                                            <span v-else-if="cart_item.tint!==null">{{cart_item.tint.price}}</span>

                                            <span v-if="cart_item.tint!==null || cart_item.glass_option!==null || cart_item.glass_type!==null">€</span>
                                        </span>
                                    </template>
                                    <template v-else>
                                        Lenses :
                                        <span v-if="cart_item.glass_option!==null"> {{cart_item.glass_option.name.replace('Verres solaires','Solar Tinted & Gradient').replace('Anti-lumière bleue (verres pour écran)','Anti Blue Light')}}</span>
                                        <span v-else>No option</span>
                                        
                                        <span v-if="cart_item.glass_type!==null"><br />Lens type:
                                            {{cart_item.glass_type.name.replace('Sans correction','No correction')}} </span>


                                        <span v-if="cart_item.tint!==null"><br />Lens tint: {{cart_item.tint.name}}</span>

                                        <span class='subtotal' v-if="(cart_item.tint!=null || cart_item.glass_option!=null  || cart_item.glass_type!=null)">

                                            <span
                                                v-if="cart_item.tint!==null && cart_item.glass_option!==null && cart_item.glass_type!==null">{{cart_item.tint.price+cart_item.glass_option.price+cart_item.glass_type.price}}</span>

                                            <span
                                                v-else-if="cart_item.tint!==null && cart_item.glass_option!==null">{{cart_item.tint.price+cart_item.glass_option.price}}</span>

                                            <span
                                                v-else-if="cart_item.glass_option!==null">{{cart_item.glass_option.price}}</span>

                                            <span v-else-if="cart_item.tint!==null">{{cart_item.tint.price}}</span>

                                            <span v-if="cart_item.tint!==null || cart_item.glass_option!==null || cart_item.glass_type!==null">€</span>
                                        </span>
                                    </template>
                                </div>
                            </div>
                            <div v-if="language=='fr'" class='product-detail' @click="toggleCart('frame', index)" :class="{open:toggleFrame[index]}">
                                <div class="product_detail_content">
                                    <div v-for="order_item_customization in cart_item.order_item_customizations" :key="order_item_customization.id">
                                        <span v-if="(order_item_customization.model_part.type=='color' || order_item_customization.model_part.type=='texture_color') && order_item_customization.model_part.render_type!='manchon' && order_item_customization.model_part.render_type!='hinge'">
                                           <span class="detail-custom" v-if="order_item_customization.model_part.type=='color'">
                                                <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            </span>
                                            <span class="detail-custom" v-else>
                                                <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            
                                            </span>
                                            <span class="detail-custom" v-if="order_item_customization.texture">
                                             
                                                Texture {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.texture.name}}
                                                <span v-if="order_item_customization.texture.price" class='subtotal'>{{order_item_customization.texture.price}}€</span>
                                            </span>
                                            <span class="detail-custom" v-if="order_item_customization.texture_style">
                                               <span v-if="language=='fr'">Style de texture</span><span v-else>Texture style</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.texture_style.name}}
                                                <span v-if="order_item_customization.texture_style.price" class='subtotal'>{{order_item_customization.texture_style.price}}€</span>
                                            </span>
                                        </span>
                                    </div>


                                    <div v-if="cart_item.engraving_text!=null && cart_item.engraving_text!=''">
                                        Gravure :
                                        {{cart_item.engraving_text}}
                                        <span class='subtotal'>10€</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else class='product-detail' @click="toggleCart('frame', index)" :class="{open:toggleFrame[index]}">
                                <div class="product_detail_content">
                                    <div v-for="order_item_customization in cart_item.order_item_customizations" :key="order_item_customization.id">
                                        <span v-if="(order_item_customization.model_part.type=='color' || order_item_customization.model_part.type=='texture_color') && order_item_customization.model_part.render_type!='manchon' && order_item_customization.model_part.render_type!='hinge'">
                                           <span class="detail-custom" v-if="order_item_customization.model_part.type=='color'">
                                                <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            </span>
                                            <span class="detail-custom" v-else>
                                                <span v-if="language=='fr'">Couleur</span><span v-else>Color</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.color.name}}
                                                <span v-if="order_item_customization.color.price" class='subtotal'>{{order_item_customization.color.price}}€</span>
                                            
                                            </span>
                                            <span class="detail-custom" v-if="order_item_customization.texture">
                                             
                                                Texture {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.texture.name}}
                                                <span v-if="order_item_customization.texture.price" class='subtotal'>{{order_item_customization.texture.price}}€</span>
                                            </span>
                                            <span class="detail-custom" v-if="order_item_customization.texture_style">
                                               <span v-if="language=='fr'">Style de texture</span><span v-else>Texture style</span> {{order_item_customization.model_part.name.replace('Temples','Branches').replace('Frame','Face')}} :
                                                {{order_item_customization.texture_style.name}}
                                                <span v-if="order_item_customization.texture_style.price" class='subtotal'>{{order_item_customization.texture_style.price}}€</span>
                                            </span>
                                        </span>
                                    </div>


                                    <div v-if="cart_item.engraving_text!=null && cart_item.engraving_text!=''">
                                        Engraving :
                                        {{cart_item.engraving_text}}
                                        <span class='subtotal'>10€</span>
                                    </div>
                                </div>
                            </div>
                          
                        </div>
                        <div class="price-item">
                           {{cart_item.amount}}€
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <span v-if="language=='fr'">Aucun produit dans le panier</span>
                <span v-else>No product</span>
            </div>
        </span>
        <span v-else>
            No product
        </span>
    </div>
     <div v-if="displayBigPhoto" @click="closeModal" class="popup-photo-big">
        <div class="content">
            <img src="../../../../public/assets/home/cross.svg" class="cross" @click="closePhotoBig" />

            <inner-image-zoom :src="bigPhoto" :zoomSrc="bigPhoto" class="photo"  />
        </div>
    </div>

</template>

<script>
import sizeSentenceFactory from '../../../data/collectionGlass/text_size.js'
import { useOrderItemStore } from '../../../store/orderItemStore'
import InnerImageZoom from 'vue-inner-image-zoom';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import CartImage from "../Cart/CartImageComponent.vue";
export default {
    name: "CartItemComponent",
    props: ["cart_items","photos","toggleGlass","toggleFrame","language"],
    components: {
        CartImage,
    'inner-image-zoom': InnerImageZoom
  },
    methods: {    

        toggleCart(str, index){
            this.$emit('toggleCartEv', str, index)
        },
        minusCartItem(e,id,quantity){
            this.$emit('minusCartItemEv', e,id,quantity)
        },
        plusCartItem(e,id){
          
            this.$emit('plusCartItemEv', e,id)
        },
        calculatePhoto(index) {
            let urlPhoto='https://api.sens-eye.fr/api/photo-single'
           
            let cart_item=this.cart_items[index]

             if(cart_item.tint!=null) {
                if(cart_item.texture_style!=null) {
                    urlPhoto+='?glass='+cart_item.glass.id+'&color='+cart_item.color.id+'&texture='+cart_item.texture.id+'&branch='+cart_item.branch.id+'&tint='+cart_item.tint.id+'&texture_style='+cart_item.texture_style.id
                }
                else {
                    urlPhoto+='?glass='+cart_item.glass.id+'&color='+cart_item.color.id+'&texture='+cart_item.texture.id+'&branch='+cart_item.branch.id+'&tint='+cart_item.tint.id
                }
            }
            else {
                if(cart_item.texture_style!=null) {
                    urlPhoto+='?glass='+cart_item.glass.id+'&color='+cart_item.color.id+'&texture='+cart_item.texture.id+'&branch='+cart_item.branch.id+'&tint=5&texture_style='+cart_item.texture_style.id
                }
                else {
                    urlPhoto+='?glass='+cart_item.glass.id+'&color='+cart_item.color.id+'&texture='+cart_item.texture.id+'&branch='+cart_item.branch.id+'&tint=5'
                }
            }

             urlPhoto+='&number=0'
            return urlPhoto
        },
        closeModal($event)
        {
           
            if(jQuery($event.target).parents('.popup-photo-big .content').length)
            {
               
            }
            else
            {
                this.displayBigPhoto=false
            }
            
        },
        displayPhotoBig(photo) {
            this.bigPhoto=photo
            this.displayBigPhoto=true
        },
        closePhotoBig() {
            this.displayBigPhoto=false
        },

                
    },
    data(){
      
        return{
            useOrderItem: useOrderItemStore(),
            sizeSentenceFactory,
            activeSizePopup: 'm',
            displayBigPhoto:false,
            bigPhoto:null,

        }
    },
    computed: {
    

    },
    mounted(){
     
    }
}
</script>

<style scoped>
@media screen and (min-width:760px) and (max-width:1300px)
{
    #cart-items .cart-item .price-item,
    #cart-items .cart-item .name-product
    {
        font-size: 14px !important;
    }
   
  
}
#cart-items .cart-item .price-item
{
    width:15%;
    font-size: 16px;
    text-align:right;
}
#cart-items .cart-item .product-details
{
    width: 100%;
    padding-left:0 !important;
    padding-right:0 !important;
    font-size:12px;
    color:#999999;
}
#cart-items .cart-item .name-product
{
    width:30%;
    font-size:16px;
    padding-left:10px;
}

#cart-items .cart-item .product-img-container{
    background:#fff;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    width:25%;
   
   height: fit-content;
}
#cart-items .cart-item .product-img
{
   width: 100%;
   
}
#cart-items .cart-item .name {
    float:left;
    font-size:18px;
    font-weight: 600;
}
.popup-photo-big {
    background:rgba(0,0,0,0.7);
    position:fixed;
    left:0;
    top:0;
    z-index:9999999;
    width:100%;
    height:100%;
}
#cart-items .cart-item .product-details .item-amount {
    width:auto !important;
    font-weight: 400;
}
.popup-photo-big .content .cross {
    position:absolute;
    right:-20px;
    top:-20px;
    cursor:pointer;
    z-index:2;
}
.popup-photo-big .content .photo {
    width:auto;
    max-height:80vh;
    margin-left:auto;
    margin-right:auto;
    position:relative;
}
.popup-photo-big .content {
    position:absolute;
    left:50%;
    background:#fff;
    top:50%;
    width:auto;
    
    max-width:80%;
    transform:translate(-50%,-50%);
}

.detail-custom
{
    display: block;
}
 #cart-items .cart-item {
    padding:0 !important;
    padding-right: 14px !important;
 }
.item-amount
{
	font-weight:500;
	float:right;
	margin-top:0px;
    text-align: right !important;
}
.product_detail_content {
    padding-bottom:0px;
    position:relative;
}

.cart-content .product-detail label
{
  display: inline-block;
}
.cart-content .product-detail
{
  font-size:12px;
  position:relative;
}
.cart-content .product-detail .subtotal
{
  display: inline-block;
      width: auto;
    clear: none;
}

.product-detail .right-icon {
  float:right;
  position:absolute;
  right:0;
  top:5px;
}
.product-detail.open .right-icon.minus{
  display:block;
}
.product-detail.open .right-icon.plus{
  display:none;
}
.right-icon.minus {
  display: none;
}


body .popup-size #cross {
    position:absolute !important;
    right:-10px !important;
    top:-10px !important;
    left:auto !important;
   
    z-index: 2;
    box-shadow:0px 3px 6px rgba(0, 0, 0, 0.16) !important ;
    padding:8px !important;
    background:#fff !important;
    border-radius: 50% !important;
    cursor:pointer;
}

.cart-items-items {
  display:flex;
  flex-direction: column-reverse;
}



.product-detail .subtotal
{
float:right;
text-align:left;
font-size:12px;
display:inline-block;
width:auto;
}
#order-review-content .cart-item .product-details
{
    width:100%;
 padding-left: 0 !important;
}
.product-detail
{
    clear:both;
display:block;
 font-size:12px;
  position: relative;
 line-height:15px;

}
.product-detail label
{
 font-size:12px;
 display:inline-block;
 font-weight:400;
 margin:0 !important;
}

</style>