<template>

          <div class="order-review" >
            <template v-if="confirmation!=true">
                <h2 v-if="language=='fr'" class="contenudesk">Ma commande ({{cart_items.length}})</h2>
                <h2 class="contenudesk" v-else>Your order ({{cart_items.length}})</h2>
                <h2 v-if="language=='fr'" class="contenumob" @click="clickToggleReview">Ma commande ({{cart_items.length}})</h2>
                <h2 v-else class="contenumob" @click="clickToggleReview">Your order ({{cart_items.length}})</h2>
            </template>
            <template v-else-if="account!=true">
                <template v-if="order">
                    <h2 v-if="language=='fr'" class="contenumob" @click="clickToggleReview"><span class="closed">Afficher</span><span class="opened">Cacher</span> votre commande</h2>
                    <h2 v-else class="contenumob" @click="clickToggleReview"><span class="closed">Display</span><span class="opened">Hide</span> your order</h2>
                    <div v-if="language=='fr'" class="title">Commande n°{{order.id}}</div>
                    <div v-else class="title">Order n°{{order.id}}</div>
                    <div class="review-elem">{{order.user.name}} {{order.user.surname}}</div>
                    <div class="review-elem">{{order.user.email}}</div>
                    </template>
                </template>
            <template v-else>
                <h2 v-if="language=='fr'" class="contenudesk">Résumé de la commande (#{{order.id}})</h2>
                <h2 class="contenudesk" v-else>Your order (#{{order.id}})</h2>
                <h2 v-if="language=='fr'" class="contenumob" @click="clickToggleReview">Résumé de la commande (#{{order.id}})</h2>
                <h2 v-else class="contenumob" @click="clickToggleReview">Your order (#{{order.id}})</h2>
           
            
           
            </template>
           
            <content-loader v-if="loaded==false"
              viewBox="0 0 410 100"
              primaryColor="#f3f3f3"
              secondaryColor="#cccccc"
              >

               <rect x="0" y="0" rx="2" ry="2" width="100" height="100" />

               <rect x="110" y="0" rx="3" ry="3" width="300" height="6" />

               <rect x="110" y="20" rx="3" ry="3" width="300" height="6" />

               <rect x="110" y="40" rx="3" ry="3" width="300" height="6" />
                 
            </content-loader>
            <div id="order-review-content" v-else>
            
            <CartItem
                @toggleCartEv="toggleCart"
                @plusCartItemEv="plusCartItem"
                @minusCartItemEv="minusCartItem"

                :language="language"
                :photos="photos"
                :cart_items="cart_items"
                :toggleGlass="toggleGlass"
                :toggleFrame="toggleFrame"
            />
            
            </div>
            <br />
            <template v-if="confirmation!=true">
                <div class="promotion" >
                    <input v-if="language=='fr'"  ref="promoInput" type="text" name="promotion" :class="{error:promotionError!=''}" id="promotion" v-on:change="promocodeFn('promocode', $event)" placeholder="Code promotionnel" /> 
                    <input v-else  ref="promoInput" type="text" name="promotion" :class="{error:promotionError!=''}" id="promotion" v-on:change="promocodeFn('promocode', $event)" placeholder="Promo code" /> 

                    <button type="submit" class="black" @click="applyPromotion">Appliquer</button>
                </div>
                
                <div id="promotion-error" class="error-form-message">{{promotionError}}</div>
                <div class="promotion_content" v-if="displayPromotion && totalLoaded">

                    <span v-if="language=='fr'">
                        <a class="unapply-promo" @click="unApplyPromotion">Supprimer la promotion active</a><br />
                        Promotion appliquée !<br /><br />
                        Sous-total panier : <span id="subtotal">{{totalOrder}}</span>€<br />
                        Montant promotion : -<span id="total_promotion">{{amount_promotion}}</span>€<br /><br />
                    </span>
                    <span v-else>
                        <a class="unapply-promo" @click="unApplyPromotion">Delete active promotion</a><br />
                        Promotion applied!<br /><br />
                        Cart subtotal: <span id="subtotal">{{totalOrder}}</span>€<br />
                        Promotion amount: -<span id="total_promotion">{{amount_promotion}}</span>€<br /><br />
                    </span>
                </div>
                
                <content-loader v-if="totalLoaded==false"
                viewBox="0 0 410 70"
                primaryColor="#f3f3f3"
                secondaryColor="#cccccc"
                >

                <rect x="0" y="0" rx="15" ry="15" width="180" height="25" />

                <rect x="210" y="0" rx="15" ry="15" width="180" height="25" />

                <rect x="0" y="40" rx="15" ry="15" width="180" height="25" />

                <rect x="210" y="40" rx="15" ry="15" width="180" height="25" />
                    
                </content-loader>
                <template v-else>
                    <div class="subtotals">
                        <div class="subtotal">
                            <span v-if="language=='fr'" class="subtotal-label">Sous-total du panier</span>
                            <span v-else class="subtotal-label">Cart subtotal</span>
                            <span id="cart-st" class="subtotal-amount">{{total}}€</span> 
                        </div>
                        <div class="subtotal">
                            <span  v-if="language=='fr'" class="subtotal-label">Tarif de livraison (Colissimo)</span>
                            <span  v-else class="subtotal-label">Delivery amount (Colissimo)</span>
                            <span class="subtotal-amount">0.00€</span>
                        </div>
                        <br /><br />
                        <div class="delivery-steps" v-if="confirmation!=true">
                            <span v-if="language=='fr'" class="delivery-step">1/ Préparation de la commande entre 6 à 8 semaines</span>
                            <span v-else class="delivery-step">1/ Order manufacturing between 6 and 8 weeks</span>
                            <span v-if="language=='fr'">2/ Colissimo - Expedition en 48h<br /></span>
                            <span v-else>2/ Colissimo - Shipping within 48h<br /></span>
                        </div>
                        
                    </div>
                    <div class="total"><span v-if="language=='fr'" class="total-label">Total commande </span><span v-else class="total-label">Total order </span><span  class="total-amount"><span id="total">{{total}}</span>€</span></div>
                    <div v-if="hasOnMeasure==true" class="message">
                        <template v-if="language=='fr'">
                            Vous commandez un produit sur-mesure.<br />Un scan facial via notre application sera nécessaire <span class="u">après le paiement.</span>
                        </template>
                        <template v-else>
                            You are ordering a custom-fit product.<br />A face scan done through our mobile application will be required <span class="u">after the payment.</span>
                        </template>
                    </div>
                </template>
            </template>

            <template v-else-if="account!=true">
               <template v-if="order">
                    <div v-if="language=='fr'" class="review-elem">Livraison</div>
                    <div v-else class="review-elem">Delivery</div>
                    <div v-if="order.address" class="review-elem">{{order.address.address1}} {{order.address.address2}}, {{order.address.postcode}} {{order.address.city}}</div>
                </template>
            </template>
            <template v-else>
                <div class="subtotals">
                        <div class="subtotal">
                            <span v-if="language=='fr'" class="subtotal-label">Sous-total du panier</span>
                            <span v-else class="subtotal-label">Cart subtotal</span>
                            <span id="cart-st" class="subtotal-amount">{{total}}€</span> 
                        </div>
                        <div class="subtotal">
                            <span  v-if="language=='fr'" class="subtotal-label">Tarif de livraison (Colissimo)</span>
                            <span  v-else class="subtotal-label">Delivery amount (Colissimo)</span>
                            <span class="subtotal-amount">0.00€</span>
                        </div>
                        
                        
                        
                    </div>
                    <div class="total"><span v-if="language=='fr'" class="total-label">Total commande </span><span v-else class="total-label">Total order </span><span  class="total-amount"><span id="total">{{total}}</span>€</span></div>
           
            </template>
            <br />
            <input class="note" type="text" v-on:change="assignValue('note', $event)" :value="note"  placeholder="Commentaire"  />
            <br />
            
            </div>
           

</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import CartItem from "./CartItemComponent.vue"

export default {
    name: "CheckoutOrderReview",
    props: [
    "language","note","totalOrder","loaded","order","account","cart_items","photos","promotionError","displayPromotion","total","amount_promotion","confirmation","totalLoaded"
    ],
    components:{
        ContentLoader,
        CartItem
    },
    methods:{
        assignValue(link,e)
        {
            this.$emit('assignValueEv', link, e.target.value)
        },
        clickToggleReview(){
        
            this.$emit('clickToggleReviewEv')
        },
        minusCartItem(e,id,quantity){
            this.$emit('minusCartItemEv', e,id,quantity)
        },
        plusCartItem(e,id){
            this.$emit('plusCartItemEv', e,id)
        },
        toggleCart(toggle,index) {
         
            if(toggle=='glass') this.toggleGlass[index]=!this.toggleGlass[index]
            if(toggle=='frame') this.toggleFrame[index]=!this.toggleFrame[index]
            if(toggle=='texture') this.toggleTexture[index]=!this.toggleTexture[index]
        },
        formatPhoto(media) {
            if(!media) return;
                let photo=media.replace('[','');
                photo=photo.replace(']','');
                photo=photo.replace("'",'');
                photo=photo.replace('"','');
            return photo
        },
        applyPromotion(){
            this.$emit('applyPromotionEv')
        },
        unApplyPromotion(){
            this.$refs.promoInput.value="";
            this.$emit('unApplyPromotionEv')
        },
        promocodeFn(link, e)
        {

            this.$emit('promocodeEv', link, e.target.value)
        }
    },
    mounted(){
        
        for(let i=0;i<this.cart_items.length;i++) {
          
            this.toggleGlass.push(false)
            this.toggleFrame.push(false)
            this.toggleTexture.push(false)
            if(this.cart_items[i].size!=null && this.cart_items[i].size!="onesize" && this.cart_items[i].size!="s"  && this.cart_items[i].size!="m"  && this.cart_items[i].size!="l" && this.cart_items[i].scan==null)
            {
               
                this.hasOnMeasure=true
            }
        }

        this.$emit('orderReviewLoadedEv')


    },
    watch:{
        
        cart_items:  function () {
          
            for(let i=0;i<this.cart_items.length;i++) {
          
       
                if(this.cart_items[i].size!=null && this.cart_items[i].size!="onesize" && this.cart_items[i].size!="s"  && this.cart_items[i].size!="m"  && this.cart_items[i].size!="l" && this.cart_items[i].scan==null)
                {
                    
                    this.hasOnMeasure=true
                }
            }
        },
    },
    
    data(){
        return{
            hasOnMeasure:false,
            toggleGlass:[],
            sizes:null,
            toggleFrame:[],
            toggleTexture:[],
        }
    }
}
</script>

<style scoped>

@media screen and (max-width:760px)
{
    .message
    {
        font-size:10px !important;
    }
    .promotion .black,
    input.note
    {
        font-size: 13px !important;
    }
    input.note
    {
        padding: 9px 14px !important;
    }
    .confirm-wrapper .order-review .title
    {
        font-size:14px !important;
    }
    .confirm-wrapper .order-review .review-elem
    {
        display: none;
    }
    .promotion .black
    {
        padding:10px 14px !important;
    }
}
input.note
{
    padding:10px 14px;
    border-radius: 5px;
    border:1px solid #999999;
    width:100%;
    color:#999999;
    background:transparent;
} 
.promotion .black
{
    background:#000;
    margin-left:5px;
    padding:11px 14px;
    white-space: nowrap;
    text-transform: none !important;
    color:#fff;
    width:fit-content;
    height:fit-content;
    font-weight: 400;
    border-radius: 5px;
    font-size: 16px;
}
.promotion
{
    display: flex;
    justify-content: space-between;
   
}
.subtotals
{
    padding-left:14px;
    padding-right: 14px; 
}
.order-review .total
{
    padding-left:14px;
    padding-right: 14px;
}
@media screen and (min-width:760px) and (max-width:1300px)
{
    input.note
    {
        padding:11px 14px !important;
        font-size: 14px !important;
    }
    .order-review .total,.order-review .total-amount
    {
        font-size: 20px !important;
    }
    .subtotal-amount,
    .subtotal .subtotal-label
    {
        font-size: 14px !important;
    }
    .promotion .black
    {
        font-size: 14px !important;
        padding:12.5px 14px !important;
    }
    .order-review .promotion input[type="text"]
    {
        padding:11px 14px !important;
        font-size: 14px !important;
     
    }
    .order-review h2 {
        font-size: 35px !important;
       
    }
}
.order-review h2.contenumob.open .closed {
    display: none;
}
.order-review h2.contenumob.open .opened {
    display: inline-block;
}
.order-review h2.contenumob .opened {
    display: none;
}
.order-review h2.contenumob::after
{
    content: " ";
    width:15px;
    height:8px;
    float: right;
    margin-top: 13px;
    background-size: 100% 100%;
    background-image:url('../../../../public/assets/images/arrow_select.svg');
}
.order-review h2.contenumob
{
    padding-bottom: 5px !important;
    font-size: 25px;
    
    clear: both;
    border-bottom: 0 !important;
}
.confirm-wrapper .order-review .title
{
    border-bottom:1px solid rgba(0, 0, 0, 0.438);
    padding-top:10px;
    padding-bottom:10px;
    font-weight: 600;
    font-size:18px;
}
.confirm-wrapper .order-review .review-elem
{
    font-size:18px;
    padding-top:10px;
    padding-bottom:10px;
    border-bottom:1px solid rgba(0, 0, 0, 0.438);
}
.confirm-wrapper .order-review
{
    min-height:80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width:50%;
    padding:5% 10%;
    overflow: auto;
    margin-left:0;
    box-shadow: none;
    width:50%;
    background: #f1f1f1 0% 0% no-repeat padding-box;
}
#order-review-content .cart-item .product-details {
    width:100% !important;
}

.order-review h2.contenumob.open:after
{
	transform:rotate(180deg);
}


.message .b {
    font-weight: 600;
}
.message .u {
    text-decoration: underline;
}
.message {
    font-size: 14px;
    margin-top:20px;
    background: #63FF2B;
    border-radius: 5px;
    color:#000;
   
    text-align: center;
    padding:14px;
    margin-bottom: 0px;
}

.subtotal
{
    line-height: 1.2;
	clear:both;
	display:block;
	width:100%;
}
.subtotals
{
	padding-top:20px;
	padding-bottom:30px;

	border-bottom:1px solid #999999;
}

.subtotal .subtotal-amount
{
	float:right;
}
.subtotal-amount {
    font-size:16px;
}
.subtotal .subtotal-label
{
    font-size:16px;
	float:left;
}
.error {
    border:1px solid red !important;
}
.error-form-message {
    color:red;
    font-size:10px;
    text-align: left;
    left:0;
    position:relative;
    top:-15px;
}
.delivery-step
{
    display: block;
    margin-bottom:3px;
}
.delivery-steps 
{
    font-size:12px;
    text-align: left;
    color:#999999;
}
.delivery-steps .number
{
    position: relative;
    background:#000;
    border-radius:50%;
    padding-top:3px;
    width:18px;
    height:18px;
    text-align: center;
    display: inline-block;
    margin-right:5px;
    font-size:9px;
    color:#fff;
}
.unapply-promo {
    color:#000;
    cursor: pointer;
    font-weight: normal;
    font-size:12px;
    margin-bottom:10px;
}
</style>